<template>
  <div id="filters-flight">
    <!-- SECTION FILTERS -->
    <b-card body-class="px-1">
      <template #header>
        <div
          class="w-100 d-flex justify-content-between align-items-center"
          @click="handleToggleShow"
        >
          <div class="d-flex-center">
            <img
              src="@icons/filters.svg"
              alt="filters"
            >
            <span class="font-weight-bold text-nowrap">{{ $t('flight.filters') }}</span>
          </div>

          <div class="d-flex-center">
            <b-button
              variant="outline-danger"
              :disabled="isDeactiveClearFilters"
              class="rounded-lg px-1 py-75 mr-1"
              @click.stop="handleClearFilter"
            >
              <span class="font-weight-bolder">{{ $t('flight.clearAllFilters') }}</span>
            </b-button>
            <IconSvg
              :src="require('@icons/triangle-down.svg')"
              blank-color="#ccc"
              class="rotate_class text-secondary"
              :style="{ transform: rotateStyleVisible, width: '12px' }"
            />
          </div>
        </div>
      </template>

      <!-- SECTION collapse Filter -->
      <b-collapse v-model="isVisible">
        <app-collapse>
          <!-- ANCHOR departure time -->
          <app-collapse-item :title="$t('flight.departureTime')">
            <div>
              <div v-if="departureTime.length">
                <p class="d-flex justify-content-between align-items-center">
                  <em class="text-muted">
                    ({{ toHoursAndMinutes(Math.min(...departureTime)) }} - {{ toHoursAndMinutes(Math.max(...departureTime)) }})
                  </em>
                </p>

                <span class="font-weight-bold text-dark">{{
                  toHoursAndMinutes(sortDepartureTime[0])
                }}</span>
                -
                <span class="font-weight-bold text-dark">
                  {{ toHoursAndMinutes(sortDepartureTime[1]) }}
                </span>

                <!-- departureTime slider -->
                <vue-slider
                  :value="sortDepartureTime"
                  :min="Math.min(...departureTime)"
                  :min-range="10"
                  :max="Math.max(...departureTime)"
                  :disabled="Math.min(...departureTime) === Math.max(...departureTime)"
                  :dot-size="20"
                  direction="ltr"
                  class="my-1 vue-slider-warning"
                  :contained="true"
                  :tooltip-formatter="toHoursAndMinutes"
                  :lazy="true"
                  :drag-on-click="true"
                  @change="val => handleChangeSlide(val, 'sortDepartureTime')"
                />
                <div class="inputFilter">
                  <b-form-group class="w-100">
                    <b-form-input
                      v-model="formattedDepartureTime[0]"
                      type="time"
                      format="HH:mm"
                      :disabled="Math.min(...departureTime) === Math.max(...departureTime)"
                      :placeholder="$t('scheduleCalendar.startTime')"
                      full-width
                      @input="updateFormattedDepartureTime(0, $event)"
                    />
                  </b-form-group>
                  <b-form-group class="w-100">
                    <b-form-input
                      v-model="formattedDepartureTime[1]"
                      type="time"
                      :disabled="Math.min(...departureTime) === Math.max(...departureTime)"
                      format="HH:mm"
                      :placeholder="$t('scheduleCalendar.endTime')"
                      @input="updateFormattedDepartureTime(1, $event)"
                    />
                  </b-form-group>
                </div>
              </div>
              <div v-else>
                {{ $t('flight.loading') }}
              </div>
            </div>
          </app-collapse-item>

          <!-- ANCHOR PRICE -->
          <app-collapse-item :title="$t('flight.rangePrice')">
            <div>
              <div v-if="minMaxPrices.length">
                <p class="d-flex justify-content-between align-items-center">
                  <em class="text-muted">
                    ({{ formatCurrency(Math.min(...minMaxPrices)) }} - {{ formatCurrency(Math.max(...minMaxPrices)) }})
                  </em>
                </p>

                <span class="font-weight-bold text-dark">{{
                  formatCurrency(sortMinMaxPrice[0])
                }}</span>
                -
                <span class="font-weight-bold text-dark">
                  {{ formatCurrency(sortMinMaxPrice[1]) }}
                </span>

                <!-- slider -->
                <vue-slider
                  :value="sortMinMaxPrice"
                  :min="Math.min(...minMaxPrices)"
                  :min-range="Math.min(...minMaxPrices)"
                  :max="Math.max(...minMaxPrices)"
                  :dot-size="20"
                  :disabled="Math.min(...minMaxPrices) === Math.max(...minMaxPrices)"
                  direction="ltr"
                  class="my-1 vue-slider-warning"
                  :contained="true"
                  :tooltip-formatter="formatCurrency"
                  :lazy="true"
                  :drag-on-click="true"
                  @change="val => handleChangeSlide(val, 'sortMinMaxPrice')"
                />
                <div class="inputFilter">
                  <b-form-group label-for="minPrice">
                    <template #label>
                      {{ $t('flight.minPrice') }}
                    </template>
                    <IAmInputMoney
                      class="flex-grow-1"
                      :value-money="sortMinMaxPrice[0]"
                      :disabled="Math.min(...minMaxPrices) === Math.max(...minMaxPrices)"
                      @input="val => handleMinPrice(val)"
                    />
                  </b-form-group>
                  <b-form-group label-for="maxPrice">
                    <template #label>
                      {{ $t('flight.maxPrice') }}
                    </template>
                    <IAmInputMoney
                      :value-money="sortMinMaxPrice[1]"
                      :disabled="Math.min(...minMaxPrices) === Math.max(...minMaxPrices)"
                      class="flex-grow-1"
                      @input="val => handleMaxPrice(val)"
                    />
                  </b-form-group>
                </div>
              </div>
              <div v-else>
                {{ $t('flight.loading') }}
              </div>
            </div>
          </app-collapse-item>

          <!-- ANCHOR AIRLINE -->
          <app-collapse-item
            :title="$t('flight.airline')"
            is-visible
          >
            <div v-if="airlines.length">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="sortAirLines"
                  :aria-describedby="ariaDescribedby"
                  name="airline-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(item, index) in sortBy(airlines, 'minPrice')"
                    :key="index"
                    class="py-25 custom-control-warning"
                    :value="item.value"
                  >
                    <div class="d-flex-center justify-content-start">
                      <IAmLogoAirline
                        :airline="item.value.split(' | ')[0]"
                        :height="20"
                        custom-class="mr-25"
                      />
                      <span
                        style="line-height: 1.3rem;"
                        class="w-100"
                      >
                        <span class="font-weight-bold text-nowrap text-truncate">{{ item.text }}
                          <span class="ml-25 font-italic">({{ item.value.split(' | ')[0] }})</span>
                        </span>
                        <div><i class="ml-25">({{ formatCurrency(item.minPrice) }})</i></div>
                      </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-else>
              {{ $t('flight.loading') }}
            </div>
          </app-collapse-item>

          <!-- ANCHOR SOURCE -->
          <app-collapse-item :title="$t('flight.airSource')">
            <div v-if="airSources.length">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="sortSources"
                  :aria-describedby="ariaDescribedby"
                  name="source-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(item, index) in airSources"
                    :key="index"
                    class="py-25 custom-control-warning"
                    :value="item.value"
                  >
                    <div class="d-flex-center gap-1 font-weight-bold text-nowrap">
                      <div
                        style="width: 30px"
                        class="d-flex-center"
                      >
                        <IAmLogoAirline
                          :airline="item.text.toUpperCase() "
                          size="xs"
                        />
                      </div>
                      <span class="flex-1">
                        {{ $te(`flight.airlines.${item.text.toUpperCase()}`)
                          ? $t(`flight.airlines.${item.text.toUpperCase()}`)
                          : '' }}
                        ({{ $te(`flight.sourceName.${item.text.toUpperCase()}`)
                          ? $t(`flight.sourceName.${item.text.toUpperCase()}`)
                          : item.text.toUpperCase() }})
                      </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-else>
              {{ $t('flight.loading') }}
            </div>
          </app-collapse-item>

          <!-- ANCHOR number of stop -->
          <app-collapse-item :title="$t('flight.numberOfStop')">
            <div v-if="stopPoint.length">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="sortStopPoint"
                  :aria-describedby="ariaDescribedby"
                  name="number-of-stop-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(item, index) in stopPoint"
                    :key="index"
                    class="py-25 custom-control-warning"
                    :value="item.value"
                  >
                    {{ $te(`flight.stopPoint.${item.text}`) ? $t(`flight.stopPoint.${item.text}`) : item.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-else>
              {{ $t('flight.loading') }}
            </div>
          </app-collapse-item>

          <!-- ANCHOR transit points -->
          <app-collapse-item :title="$t('flight.transitPoints')">
            <div v-if="transitPoints.length">
              <b-form-group v-slot="{ ariaDescribedby }">
                <!-- :options="transitPoints" -->
                <b-form-checkbox-group
                  v-model="sortTransit"
                  :aria-describedby="ariaDescribedby"
                  name="transit-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(transitPoint, indexTransitPoint) in transitPoints"
                    :key="indexTransitPoint"
                    class="py-25 custom-control-warning"
                    :value="transitPoint.value"
                  >
                    {{ transitPoint.text }} ({{ transitPoint.value }})
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div
              v-else
              class="font-italic"
            >
              {{ $t('noSuitableOptions') }}
            </div>
          </app-collapse-item>

          <!-- ANCHOR duration -->
          <app-collapse-item :title="$t('flight.flightDuration')">
            <div>
              <div v-if="durations.length">
                <p class="d-flex justify-content-between align-items-center">
                  <em class="text-muted">
                    ({{ toHoursAndMinutes(Math.min(...durations)) }} - {{ toHoursAndMinutes(Math.max(...durations)) }})
                  </em>
                </p>

                <span class="font-weight-bold text-dark">{{
                  toHoursAndMinutes(sortDuration[0])
                }}</span>
                -
                <span class="font-weight-bold text-dark">
                  {{ toHoursAndMinutes(sortDuration[1]) }}
                </span>

                <!-- slider -->
                <vue-slider
                  :value="sortDuration"
                  :min="Math.min(...durations)"
                  :min-range="10"
                  :max="Math.max(...durations)"
                  :disabled="Math.min(...durations) === Math.max(...durations)"
                  :dot-size="20"
                  direction="ltr"
                  class="my-1 vue-slider-warning"
                  :contained="true"
                  :tooltip-formatter="toHoursAndMinutes"
                  :lazy="true"
                  :drag-on-click="true"
                  @change="val => handleChangeSlide(val, 'sortDuration')"
                />
              </div>
              <div v-else>
                {{ $t('flight.loading') }}
              </div>
            </div>
          </app-collapse-item>

          <!-- ANCHOR AIRCRAFT -->
          <app-collapse-item :title="$t('flight.airCraft')">
            <div v-if="airCrafts.length">
              <b-form-group v-slot="{ ariaDescribedby }">
                <!-- value-field="value"
                text-field="text"
                :options="airCrafts" -->
                <b-form-checkbox-group
                  v-model="sortAirCrafts"
                  :aria-describedby="ariaDescribedby"
                  name="airline-filter"
                  stacked
                >
                  <b-form-checkbox
                    v-for="(airCraft, indexAirCraft) in airCrafts"
                    :key="indexAirCraft"
                    class="py-25 custom-control-warning"
                    :value="airCraft.value"
                  >
                    {{ airCraft.text === 'Unknown' ? $t(airCraft.text) : airCraft.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <div v-else>
              {{ $t('flight.loading') }}
            </div>
          </app-collapse-item>

          <!-- ANCHOR fareType business -->
          <app-collapse-item :title="$t('flight.fareType')">
            <b-form-group>
              <b-form-checkbox
                v-if="filterSkyboss"
                id="skyboss-fare-type-filter"
                v-model="sortFareTypeSkyboss"
                name="skyboss-fare-type-filter"
                class="py-25 custom-control-warning"
              >
                <span class="font-weight-bold text-nowrap">SkyBoss</span>
              </b-form-checkbox>

              <b-form-checkbox
                id="business-fare-type-filter"
                v-model="sortFareTypeBusiness"
                name="business-fare-type-filter"
                class="py-25 custom-control-warning"
              >
                <span class="font-weight-bold text-nowrap">Business</span>
              </b-form-checkbox>
            </b-form-group>
          </app-collapse-item>
        </app-collapse>
      </b-collapse>
      <!-- !SECTION -->
    </b-card>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BButton,
  BCard,
  BFormInput,
  BCollapse,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { ref, computed } from '@vue/composition-api'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

import store from '@/store'

import { formatCurrency, toHoursAndMinutes } from '@core/utils/filter'

export default {
  components: {
    VueSlider,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCollapse,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortStopPoint: [],
      sortTransit: [],
      sortSources: [],
      sortAirLines: [],
      sortMinMaxPrice: [0, 0],
      sortDuration: [0, 0],
      sortDepartureTime: [0, 0],
      sortAirCrafts: [],
      sortFareTypeBusiness: false,
      sortFareTypeSkyboss: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },

  computed: {
    airSources() {
      const value = this.$store.getters['app-flight-v2/getFilterSources']
      return value
    },

    airlines() {
      const value = this.$store.getters['app-flight-v2/getFilterAirlines']
      return value
    },

    stopPoint() {
      const value = this.$store.getters['app-flight-v2/getFilterStopPoints']
      return value
    },

    minMaxPrices() {
      const value = this.$store.getters['app-flight-v2/getFilterPrices']
      return value
    },

    transitPoints() {
      const value = this.$store.getters['app-flight-v2/getFilterTransitPoints']
      return value
    },

    durations() {
      const value = this.$store.getters['app-flight-v2/getFilterDurations']
      return value
    },

    airCrafts() {
      const value = this.$store.getters['app-flight-v2/getFilterAirCrafts']
      return value
    },

    departureTime() {
      const value = this.$store.getters['app-flight-v2/getFilterDepartureTime']
      return value
    },

    formattedDepartureTime() {
      const newValue = this.departureTime.map(item => {
        const duration = moment.duration(item, 'minutes')
        return moment.utc(duration.as('milliseconds')).format('HH:mm')
      })
      return newValue
    },

    arrivalTime() {
      const value = this.$store.getters['app-flight-v2/getFilterArrivalTime']
      return value
    },

    filterSkyboss() {
      const value = this.$store.getters['app-flight-v2/getFilterSkyboss']
      return value
    },

    isDeactiveClearFilters() {
      if (
        !this.sortStopPoint.length
        && !this.sortTransit.length
        && !this.sortSources.length
        && !this.sortAirLines.length
        && !this.sortAirCrafts.length
        && !this.sortFareTypeBusiness
        && !this.sortFareTypeSkyboss
        && this.sortMinMaxPrice[0] === Math.min(...this.minMaxPrices)
        && this.sortMinMaxPrice[1] === Math.max(...this.minMaxPrices)
        && this.sortDuration[0] === Math.min(...this.durations)
        && this.sortDuration[1] === Math.max(...this.durations)
        && this.sortDepartureTime[0] === Math.min(...this.departureTime)
        && this.sortDepartureTime[1] === Math.max(...this.departureTime)
      ) return true
      return false
    },
  },

  watch: {
    minMaxPrices(value) {
      this.sortMinMaxPrice[0] = Math.min(...value)
      this.sortMinMaxPrice[1] = Math.max(...value)
    },

    durations(value) {
      this.sortDuration[0] = Math.min(...value)
      this.sortDuration[1] = Math.max(...value)
    },

    sortMinMaxPrice(value) {
      this.sortMinMaxPriceHandle(value)
    },

    departureTime(value) {
      this.sortDepartureTime[0] = Math.min(...value)
      this.sortDepartureTime[1] = Math.max(...value)
    },

    sortDepartureTime(value) {
      this.sortDepartureTimeHandle(value)
    },

    sortSources(value) {
      this.sortSourcesHandle(value)
    },

    sortAirLines(value) {
      this.sortAirLinesHandle(value)
    },

    sortStopPoint(value) {
      this.sortStopPointHandle(value)
    },

    sortTransit(value) {
      this.sortTransitHandle(value)
    },

    sortDuration(value) {
      this.sortDurationHandle(value)
    },

    sortAirCrafts(value) {
      this.sortAirCraftsHandle(value)
    },

    sortFareTypeBusiness(value) {
      this.sortFareTypeBusinessHandle(value)
    },

    sortFareTypeSkyboss(value) {
      this.sortFareTypeSkybossHandle(value)
    },
  },

  methods: {
    sortGroupPrice() {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$emit('sortGroupPrice')
        clearTimeout(timeoutId)
      }, 100)
    },

    sortGroupFastest() {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$emit('sortGroupFastest')
        clearTimeout(timeoutId)
      }, 100)
    },

    sortGroupEarliest() {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$emit('sortGroupEarliest')
        clearTimeout(timeoutId)
      }, 100)
    },
    sortMinMaxPriceHandle(value) {
      if (value.every(item => !item)) return
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByPrices', value)
        clearTimeout(timeoutId)
      }, 100)
    },

    sortSourcesHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterBySources', value)
        clearTimeout(timeoutId)
      }, 100)
    },

    sortAirLinesHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByAirlines', value)
        clearTimeout(timeoutId)
      }, 100)
    },

    sortStopPointHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByStopPoints', value)
        clearTimeout(timeoutId)
      }, 100)
    },
    sortTransitHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByTransitPoints', value)
        clearTimeout(timeoutId)
      }, 100)
    },

    sortDurationHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByDurations', value)
        clearTimeout(timeoutId)
      }, 100)
    },

    sortDepartureTimeHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByDepartureTime', value)
        clearTimeout(timeoutId)
      }, 100)
    },
    sortAirCraftsHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByAirCrafts', value)
        clearTimeout(timeoutId)
      }, 100)
    },
    sortFareTypeBusinessHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByFareTypeBusiness', value)
        clearTimeout(timeoutId)
      }, 100)
    },
    sortFareTypeSkybossHandle(value) {
      this.$store.dispatch('app-flight-v2/setLoading', true)
      const timeoutId = setTimeout(() => {
        this.$store.dispatch('app-flight-v2/setLoading', true)
        this.$store.dispatch('app-flight-v2/setFilterByFareTypeSkyboss', value)
        clearTimeout(timeoutId)
      }, 100)
    },
    updateFormattedDepartureTime(index, newValue) {
      this.$nextTick(() => {
        const updatedFormattedDepartureTime = [...this.formattedDepartureTime]
        updatedFormattedDepartureTime[index] = newValue

        // Convert the updated values back to the format used in departureTime
        const updatedDepartureTime = updatedFormattedDepartureTime.map(item => {
          const duration = moment.duration(item, 'HH:mm')
          return duration.as('minutes')
        })

        const isValid = updatedDepartureTime.every(item => item >= Math.min(...this.departureTime) && item <= Math.max(...this.departureTime))

        if (isValid) {
          this.sortDepartureTime = updatedDepartureTime
        }
      })
    },
  },
  setup() {
    const sortOptions = ref([
      { text: 'fastestFlight', value: 'fastest' },
      { text: 'earliestFlight', value: 'earliest' },
      { text: 'cheapestFlight', value: 'cheapest' },
    ])
    const sortValue = ref({ text: 'cheapestFlight', value: 'cheapest' })

    function handleClearFilter() {
      this.sortStopPoint = []
      this.sortTransit = []
      this.sortSources = []
      this.sortAirLines = []
      this.sortAirCrafts = []
      this.sortFareType = []
      this.sortDuration = [Math.min(...this.durations), Math.max(...this.durations)]
      this.sortDepartureTime = [Math.min(...this.departureTime), Math.max(...this.departureTime)]
      this.sortMinMaxPrice = [Math.min(...this.minMaxPrices), Math.max(...this.minMaxPrices)]
    }

    // eslint-disable-next-line func-names
    const handleChangeSlide = debounce(function (val, dataName) {
      this[dataName] = val
    }, 100)

    // eslint-disable-next-line func-names
    const handleMinPrice = debounce(function (val) {
      if (val >= Math.min(...this.minMaxPrices) && val <= Math.max(...this.minMaxPrices)) {
        this.sortMinMaxPrice = [val, this.sortMinMaxPrice[1]]
      }
    }, 500)

    // eslint-disable-next-line func-names
    const handleMaxPrice = debounce(function (val) {
      if (val >= Math.min(...this.minMaxPrices) && val <= Math.max(...this.minMaxPrices)) {
        this.sortMinMaxPrice = [this.sortMinMaxPrice[0], val]
      }
    }, 500)

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

    const isVisible = ref(true)
    const rotateStyleVisible = computed(() => isVisible.value ? 'rotate(0deg)' : 'rotate(-180deg)')
    const handleToggleShow = () => {
      isVisible.value = !isVisible.value
    }

    return {
      formatCurrency,
      toHoursAndMinutes,
      sortOptions,
      sortValue,
      handleClearFilter,
      handleChangeSlide,
      handleMinPrice,
      handleMaxPrice,
      sortBy,
      isRoleF1,

      isVisible,
      rotateStyleVisible,
      handleToggleShow,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
#sort-select ::v-deep {
  .vs__dropdown-toggle {
    border: none;
  }
}

.inputFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 1536px) {
    flex-direction: column;
  }
}

// #filters-flight ::v-deep {
//   position: sticky;
//   top: 70px;
// }

.rotate_class {
  transition: transform 0.3s ease;
}
</style>
