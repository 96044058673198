var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "filters-flight"
    }
  }, [_c('b-card', {
    attrs: {
      "body-class": "px-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between align-items-center",
          on: {
            "click": _vm.handleToggleShow
          }
        }, [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('img', {
          attrs: {
            "src": require("@icons/filters.svg"),
            "alt": "filters"
          }
        }), _c('span', {
          staticClass: "font-weight-bold text-nowrap"
        }, [_vm._v(_vm._s(_vm.$t('flight.filters')))])]), _c('div', {
          staticClass: "d-flex-center"
        }, [_c('b-button', {
          staticClass: "rounded-lg px-1 py-75 mr-1",
          attrs: {
            "variant": "outline-danger",
            "disabled": _vm.isDeactiveClearFilters
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleClearFilter($event);
            }
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t('flight.clearAllFilters')))])]), _c('IconSvg', {
          staticClass: "rotate_class text-secondary",
          style: {
            transform: _vm.rotateStyleVisible,
            width: '12px'
          },
          attrs: {
            "src": require('@icons/triangle-down.svg'),
            "blank-color": "#ccc"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-collapse', {
    model: {
      value: _vm.isVisible,
      callback: function callback($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_c('app-collapse', [_c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.departureTime')
    }
  }, [_c('div', [_vm.departureTime.length ? _c('div', [_c('p', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('em', {
    staticClass: "text-muted"
  }, [_vm._v(" (" + _vm._s(_vm.toHoursAndMinutes(Math.min.apply(Math, _vm.departureTime))) + " - " + _vm._s(_vm.toHoursAndMinutes(Math.max.apply(Math, _vm.departureTime))) + ") ")])]), _c('span', {
    staticClass: "font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.toHoursAndMinutes(_vm.sortDepartureTime[0])))]), _vm._v(" - "), _c('span', {
    staticClass: "font-weight-bold text-dark"
  }, [_vm._v(" " + _vm._s(_vm.toHoursAndMinutes(_vm.sortDepartureTime[1])) + " ")]), _c('vue-slider', {
    staticClass: "my-1 vue-slider-warning",
    attrs: {
      "value": _vm.sortDepartureTime,
      "min": Math.min.apply(Math, _vm.departureTime),
      "min-range": 10,
      "max": Math.max.apply(Math, _vm.departureTime),
      "disabled": Math.min.apply(Math, _vm.departureTime) === Math.max.apply(Math, _vm.departureTime),
      "dot-size": 20,
      "direction": "ltr",
      "contained": true,
      "tooltip-formatter": _vm.toHoursAndMinutes,
      "lazy": true,
      "drag-on-click": true
    },
    on: {
      "change": function change(val) {
        return _vm.handleChangeSlide(val, 'sortDepartureTime');
      }
    }
  }), _c('div', {
    staticClass: "inputFilter"
  }, [_c('b-form-group', {
    staticClass: "w-100"
  }, [_c('b-form-input', {
    attrs: {
      "type": "time",
      "format": "HH:mm",
      "disabled": Math.min.apply(Math, _vm.departureTime) === Math.max.apply(Math, _vm.departureTime),
      "placeholder": _vm.$t('scheduleCalendar.startTime'),
      "full-width": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateFormattedDepartureTime(0, $event);
      }
    },
    model: {
      value: _vm.formattedDepartureTime[0],
      callback: function callback($$v) {
        _vm.$set(_vm.formattedDepartureTime, 0, $$v);
      },
      expression: "formattedDepartureTime[0]"
    }
  })], 1), _c('b-form-group', {
    staticClass: "w-100"
  }, [_c('b-form-input', {
    attrs: {
      "type": "time",
      "disabled": Math.min.apply(Math, _vm.departureTime) === Math.max.apply(Math, _vm.departureTime),
      "format": "HH:mm",
      "placeholder": _vm.$t('scheduleCalendar.endTime')
    },
    on: {
      "input": function input($event) {
        return _vm.updateFormattedDepartureTime(1, $event);
      }
    },
    model: {
      value: _vm.formattedDepartureTime[1],
      callback: function callback($$v) {
        _vm.$set(_vm.formattedDepartureTime, 1, $$v);
      },
      expression: "formattedDepartureTime[1]"
    }
  })], 1)], 1)], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.rangePrice')
    }
  }, [_c('div', [_vm.minMaxPrices.length ? _c('div', [_c('p', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('em', {
    staticClass: "text-muted"
  }, [_vm._v(" (" + _vm._s(_vm.formatCurrency(Math.min.apply(Math, _vm.minMaxPrices))) + " - " + _vm._s(_vm.formatCurrency(Math.max.apply(Math, _vm.minMaxPrices))) + ") ")])]), _c('span', {
    staticClass: "font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.sortMinMaxPrice[0])))]), _vm._v(" - "), _c('span', {
    staticClass: "font-weight-bold text-dark"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.sortMinMaxPrice[1])) + " ")]), _c('vue-slider', {
    staticClass: "my-1 vue-slider-warning",
    attrs: {
      "value": _vm.sortMinMaxPrice,
      "min": Math.min.apply(Math, _vm.minMaxPrices),
      "min-range": Math.min.apply(Math, _vm.minMaxPrices),
      "max": Math.max.apply(Math, _vm.minMaxPrices),
      "dot-size": 20,
      "disabled": Math.min.apply(Math, _vm.minMaxPrices) === Math.max.apply(Math, _vm.minMaxPrices),
      "direction": "ltr",
      "contained": true,
      "tooltip-formatter": _vm.formatCurrency,
      "lazy": true,
      "drag-on-click": true
    },
    on: {
      "change": function change(val) {
        return _vm.handleChangeSlide(val, 'sortMinMaxPrice');
      }
    }
  }), _c('div', {
    staticClass: "inputFilter"
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "minPrice"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('flight.minPrice')) + " ")];
      },
      proxy: true
    }], null, false, 1088572822)
  }, [_c('IAmInputMoney', {
    staticClass: "flex-grow-1",
    attrs: {
      "value-money": _vm.sortMinMaxPrice[0],
      "disabled": Math.min.apply(Math, _vm.minMaxPrices) === Math.max.apply(Math, _vm.minMaxPrices)
    },
    on: {
      "input": function input(val) {
        return _vm.handleMinPrice(val);
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "maxPrice"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('flight.maxPrice')) + " ")];
      },
      proxy: true
    }], null, false, 453108744)
  }, [_c('IAmInputMoney', {
    staticClass: "flex-grow-1",
    attrs: {
      "value-money": _vm.sortMinMaxPrice[1],
      "disabled": Math.min.apply(Math, _vm.minMaxPrices) === Math.max.apply(Math, _vm.minMaxPrices)
    },
    on: {
      "input": function input(val) {
        return _vm.handleMaxPrice(val);
      }
    }
  })], 1)], 1)], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.airline'),
      "is-visible": ""
    }
  }, [_vm.airlines.length ? _c('div', [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "airline-filter",
            "stacked": ""
          },
          model: {
            value: _vm.sortAirLines,
            callback: function callback($$v) {
              _vm.sortAirLines = $$v;
            },
            expression: "sortAirLines"
          }
        }, _vm._l(_vm.sortBy(_vm.airlines, 'minPrice'), function (item, index) {
          return _c('b-form-checkbox', {
            key: index,
            staticClass: "py-25 custom-control-warning",
            attrs: {
              "value": item.value
            }
          }, [_c('div', {
            staticClass: "d-flex-center justify-content-start"
          }, [_c('IAmLogoAirline', {
            attrs: {
              "airline": item.value.split(' | ')[0],
              "height": 20,
              "custom-class": "mr-25"
            }
          }), _c('span', {
            staticClass: "w-100",
            staticStyle: {
              "line-height": "1.3rem"
            }
          }, [_c('span', {
            staticClass: "font-weight-bold text-nowrap text-truncate"
          }, [_vm._v(_vm._s(item.text) + " "), _c('span', {
            staticClass: "ml-25 font-italic"
          }, [_vm._v("(" + _vm._s(item.value.split(' | ')[0]) + ")")])]), _c('div', [_c('i', {
            staticClass: "ml-25"
          }, [_vm._v("(" + _vm._s(_vm.formatCurrency(item.minPrice)) + ")")])])])], 1)]);
        }), 1)];
      }
    }], null, false, 472121357)
  })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.airSource')
    }
  }, [_vm.airSources.length ? _c('div', [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "source-filter",
            "stacked": ""
          },
          model: {
            value: _vm.sortSources,
            callback: function callback($$v) {
              _vm.sortSources = $$v;
            },
            expression: "sortSources"
          }
        }, _vm._l(_vm.airSources, function (item, index) {
          return _c('b-form-checkbox', {
            key: index,
            staticClass: "py-25 custom-control-warning",
            attrs: {
              "value": item.value
            }
          }, [_c('div', {
            staticClass: "d-flex-center gap-1 font-weight-bold text-nowrap"
          }, [_c('div', {
            staticClass: "d-flex-center",
            staticStyle: {
              "width": "30px"
            }
          }, [_c('IAmLogoAirline', {
            attrs: {
              "airline": item.text.toUpperCase(),
              "size": "xs"
            }
          })], 1), _c('span', {
            staticClass: "flex-1"
          }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(item.text.toUpperCase())) ? _vm.$t("flight.airlines.".concat(item.text.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(item.text.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(item.text.toUpperCase())) : item.text.toUpperCase()) + ") ")])])]);
        }), 1)];
      }
    }], null, false, 1725097083)
  })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.numberOfStop')
    }
  }, [_vm.stopPoint.length ? _c('div', [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var ariaDescribedby = _ref3.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "number-of-stop-filter",
            "stacked": ""
          },
          model: {
            value: _vm.sortStopPoint,
            callback: function callback($$v) {
              _vm.sortStopPoint = $$v;
            },
            expression: "sortStopPoint"
          }
        }, _vm._l(_vm.stopPoint, function (item, index) {
          return _c('b-form-checkbox', {
            key: index,
            staticClass: "py-25 custom-control-warning",
            attrs: {
              "value": item.value
            }
          }, [_vm._v(" " + _vm._s(_vm.$te("flight.stopPoint.".concat(item.text)) ? _vm.$t("flight.stopPoint.".concat(item.text)) : item.text) + " ")]);
        }), 1)];
      }
    }], null, false, 2450245909)
  })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.transitPoints')
    }
  }, [_vm.transitPoints.length ? _c('div', [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var ariaDescribedby = _ref4.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "transit-filter",
            "stacked": ""
          },
          model: {
            value: _vm.sortTransit,
            callback: function callback($$v) {
              _vm.sortTransit = $$v;
            },
            expression: "sortTransit"
          }
        }, _vm._l(_vm.transitPoints, function (transitPoint, indexTransitPoint) {
          return _c('b-form-checkbox', {
            key: indexTransitPoint,
            staticClass: "py-25 custom-control-warning",
            attrs: {
              "value": transitPoint.value
            }
          }, [_vm._v(" " + _vm._s(transitPoint.text) + " (" + _vm._s(transitPoint.value) + ") ")]);
        }), 1)];
      }
    }], null, false, 345936387)
  })], 1) : _c('div', {
    staticClass: "font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t('noSuitableOptions')) + " ")])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.flightDuration')
    }
  }, [_c('div', [_vm.durations.length ? _c('div', [_c('p', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('em', {
    staticClass: "text-muted"
  }, [_vm._v(" (" + _vm._s(_vm.toHoursAndMinutes(Math.min.apply(Math, _vm.durations))) + " - " + _vm._s(_vm.toHoursAndMinutes(Math.max.apply(Math, _vm.durations))) + ") ")])]), _c('span', {
    staticClass: "font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.toHoursAndMinutes(_vm.sortDuration[0])))]), _vm._v(" - "), _c('span', {
    staticClass: "font-weight-bold text-dark"
  }, [_vm._v(" " + _vm._s(_vm.toHoursAndMinutes(_vm.sortDuration[1])) + " ")]), _c('vue-slider', {
    staticClass: "my-1 vue-slider-warning",
    attrs: {
      "value": _vm.sortDuration,
      "min": Math.min.apply(Math, _vm.durations),
      "min-range": 10,
      "max": Math.max.apply(Math, _vm.durations),
      "disabled": Math.min.apply(Math, _vm.durations) === Math.max.apply(Math, _vm.durations),
      "dot-size": 20,
      "direction": "ltr",
      "contained": true,
      "tooltip-formatter": _vm.toHoursAndMinutes,
      "lazy": true,
      "drag-on-click": true
    },
    on: {
      "change": function change(val) {
        return _vm.handleChangeSlide(val, 'sortDuration');
      }
    }
  })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.airCraft')
    }
  }, [_vm.airCrafts.length ? _c('div', [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var ariaDescribedby = _ref5.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "airline-filter",
            "stacked": ""
          },
          model: {
            value: _vm.sortAirCrafts,
            callback: function callback($$v) {
              _vm.sortAirCrafts = $$v;
            },
            expression: "sortAirCrafts"
          }
        }, _vm._l(_vm.airCrafts, function (airCraft, indexAirCraft) {
          return _c('b-form-checkbox', {
            key: indexAirCraft,
            staticClass: "py-25 custom-control-warning",
            attrs: {
              "value": airCraft.value
            }
          }, [_vm._v(" " + _vm._s(airCraft.text === 'Unknown' ? _vm.$t(airCraft.text) : airCraft.text) + " ")]);
        }), 1)];
      }
    }], null, false, 941298015)
  })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.loading')) + " ")])]), _c('app-collapse-item', {
    attrs: {
      "title": _vm.$t('flight.fareType')
    }
  }, [_c('b-form-group', [_vm.filterSkyboss ? _c('b-form-checkbox', {
    staticClass: "py-25 custom-control-warning",
    attrs: {
      "id": "skyboss-fare-type-filter",
      "name": "skyboss-fare-type-filter"
    },
    model: {
      value: _vm.sortFareTypeSkyboss,
      callback: function callback($$v) {
        _vm.sortFareTypeSkyboss = $$v;
      },
      expression: "sortFareTypeSkyboss"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-nowrap"
  }, [_vm._v("SkyBoss")])]) : _vm._e(), _c('b-form-checkbox', {
    staticClass: "py-25 custom-control-warning",
    attrs: {
      "id": "business-fare-type-filter",
      "name": "business-fare-type-filter"
    },
    model: {
      value: _vm.sortFareTypeBusiness,
      callback: function callback($$v) {
        _vm.sortFareTypeBusiness = $$v;
      },
      expression: "sortFareTypeBusiness"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-nowrap"
  }, [_vm._v("Business")])])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }